/*@tailwind base;*/
@tailwind components;
@tailwind utilities;
:root {
    --link-color:hsl(208, 56%, 46%);
    --main-color:#e9e1e1;
    --background-color:rgb(214 79 73)
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    background-image: linear-gradient(#000000,#000000)   !important ;
    /*background-image: linear-gradient(#000000)   !important ;*/
    background-size: 100% 600% !important;
    background-repeat: no-repeat !important ;
    min-width: 100%;
    padding-right: 0px !important;
    overflow-y: auto;

    /*解决弹框以后页面偏移的问题*/

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: #000000;
    text-decoration: none;
}
button {
    margin-top: -20px;
    display: inline-block;
    font-family: RamblaBold, serif;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid #000;
    padding: 0.35rem 0.75rem;
    font-weight: 700;
    font-size: 23px;
    border-radius: 25px;
    color: #fff;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}


