.home-container {
    width: 100%;
}

button:active {
    transform:scale(98%);
}

.btn-icon img:hover {
    filter: brightness(0.85);
}

.navbar-collapse1 {
    float: left !important;
}

.logo-text-wrapper {
    display: inline-block;
}

input:disabled {
    border:solid 2px black;
    background-color:#ffffff;
    color:black;
    opacity: 1; /* required on iOS */
}

.ranking_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .ranking_title {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    
  }

  .ranking_table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 30px;
    overflow: hidden;
  }


.ranking_table_header_left {
    text-align: center;
    color: var(--main-color) ;
    margin-bottom: 50px;


  }


.navbar {
    background-color: var(--main-color);
    height: 125px;
    border-radius: 0 !important;
    filter: drop-shadow(1px 1px 1px rgb(117, 117, 117));
    margin-bottom: 0px !important;
    width: 100vw;
    z-index: 111;
    display: none;
}

.navbar-brand {
    margin-left: 80px !important;
}

.nav-container {
    padding-left: 100px;
    padding-right: 50px;
    border-radius: 50px;
    border: solid 2px black;
    filter: drop-shadow(1px 2px 3px rgb(65, 65, 65));
    margin-top: 45px;
    margin-left: 70px;
    width: 780px;
}

.nav-btn-group {
    margin-left: 50px;
    margin-top: 64px;
    float: right;
}

.nav-btn-group .btn-icon {
    border-radius: 0px;
    border: none;
}

.btn-connect {
    position: absolute !important;
    padding: 5px 20px;
    background-color: #4651ea;
    font-size: 26px;
    -webkit-box-shadow: inset 1px 1px 1px rgb(255, 255, 255), inset -1px -1px 1px rgb(77, 64, 72);
    margin-top: 46px;
    float: right;
    right: 0px;
    margin-right: 125px;
}

.slider-container-div {
    width: 100vw;
    justify-content: center;

    /* overflow: hidden; */
}

.slider-mask {
    overflow: hidden;
    justify-content: center;

}

.gridcontainer{
    width:100%;
    display: flex;
    justify-content: space-between;
}

.chainImage{
    height:1.5em;
    margin-right: 10px;
    margin-top: -3px;
    margin-bottom: -4px;
}

.sendcontainer{
    width:100%;
    display: flex;
    justify-content: space-between;
}

.bridgebox{
    width:100%;
    display: flex;
    justify-content: space-around;
}

.bridgetablepanel{
    width:90%;
}


.name_font{
    color:rgb(209, 205, 205);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size:15px;
}

.receive_font{
    color:rgb(209, 205, 205);
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    font-size:15px;
}

.send_box{
    color:white;
    width: 30%;
    margin-top: 10px;
}
.receive_box{
    color:white;
    width: 30%;
    margin-top: 10px;
}
.balance_box{
    margin-top: 10px;
    width: 68%;
}
.send_font{
    color:rgb(209, 205, 205);
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    font-size:15px;
}

.receive_font{
    color:rgb(209, 205, 205);
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    font-size:15px;
}

.receive_box{
    color:white;
    width: 30%;
    margin-top: 10px;
}

.balance_font{
    color:rgb(209, 205, 205);
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    font-size:15px;
    cursor: pointer;
}

.max_font{
    color:rgb(209, 205, 205);
    font-size:10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button_div{
    width: 50%;
    margin-top: 50px;
}

.button{
    width: 80%;
    margin: auto;
    margin-top: 30px;
}

.mint_logo{
    width: 100px;
}

.input_div{
    display: flex;
    justify-content: flex-start;

}

.slider-container {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;

    /* overflow: hidden;     */
}

.secondary {
    margin-top: 100px;
    justify-content: center;

}

.slider-container li {

}

.flex_container_partners-mobile{
    display: none;
}

.slider-container1 li img {
    margin: 15px;
    width: 300px;
    border-radius: 15px;
    border: solid white 15px;
    box-sizing: content-box;
    transform: rotate(356deg);
}



.slider-container li img {
    margin: 15px;
    width: 300px;
    border-radius: 15px;
    border: solid white 15px;
    box-sizing: content-box;
}



.slider-container li:nth-child(1) img {
}

.slider-container li:nth-child(2) img {
}

.slider-container li:nth-child(3) img {
}

.slider-container li:nth-child(4) img {
}


.flex_container_partners {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    width: 100%;
    justify-content: center;

}

.flex_container_partners img {
    display: inline-block;
    height: 72px!important;
    margin-right: 15px!important;
    width: 244px!important;
    vertical-align: middle;

}

.img {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
}

.star-btn-container {
    margin-top: 50px;
    padding: 100px;
    margin-bottom: 2%;
}

.btn-grab {
    padding: 15px 70px;
    font-size: 40px;
    background-color: #4853eb;
    -webkit-box-shadow: inset 1px 1px 1px rgb(255, 255, 255), inset -1px -1px 1px rgb(77, 64, 72);
    float: right;
    right: 0px;
    border-radius: 50px;
    margin-top: 20px;
    position: relative;
    z-index: 11;
}

.nav-btn-group img {
    height: 50px;
}

.logo {
    display: inline-block !important;
    height: 100px;
    filter: drop-shadow(1px 1px 1px rgb(31, 31, 31));
}

.logo-text {
    display: inline-block !important;
    height: 100px;
    image-rendering: pixelated;
    filter: drop-shadow(1px 1px 1px rgb(27, 27, 27));
}

.nav-title {
    color: #fff !important;
    font-family: SWEETGIRL;
    font-size: 36px;

}

.main-section-title {
    margin-top: 100px;
    display: block;
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    color:rgb(245, 119, 117) !important;
    font-size: 9.5vw;
    z-index: -1;
}

.name_style {
    display: block;
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    font-size: 2.5vw;
}

.div_style {
    display: block;
    color: var(--main-color) !important;
}
.list_style{
    width: 100%;
    color: #FFFFFF;
    background-color: 'background.paper';
}

.main-section-title-mobile {
    letter-spacing: 5px;
    margin-top: 80px;
    display: none;
    font-family: All-round-gothic;
    color:rgb(245, 119, 117) !important;
    font-size: 130px;

}

.head-section-title {
    display: block;
    margin-left: 1%;
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    font-size: 2.5vw;

}

.head-section-title-mobile {
    letter-spacing: 5px;
    display: none;
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    font-size: 130px;

}

.head-section-img {
    display: block;
    margin-left: 12%;
    margin-top: 2%;
    height:80px;
    width:80px;
}

.main-section-subtitle-mobile {
    letter-spacing: 5px;
    margin-top: -20px;
    display: none;
    color:rgb(245, 119, 117) !important;
    font-family: All-round-gothic;
    font-size: 95px;
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}

.main-question-title {
    margin-top: 80px;
    display: block;
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    font-size: 6vw;
    text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}

.main-parnters-title {
    margin-top: 80px;
    display: block;
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    font-size: 3vw;
    text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}

.main-section-img-container {
    width: 90vw;
    margin-top: 15px;
}

.nav-title:hover {
    color: #fddddd !important;
    font-family: All-round-gothic;
    font-size: 36px;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.main-section {
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#myNavbar1 {
    display: inline-block;
}

.img-flyingbuddies {
    width: 13vw;
    margin-left: -13%;
    margin-bottom: 25%;

}
.home-head-wrapper1 {
     width: 100%;
     height: 70px;
  }

  .home-head-wrapper {
  display:grid;
  grid-template-columns:repeat(auto-fit,minmax(200px,1fr));
  gap:10px;
 }

.foot{
display: flex;
    justify-content: space-between;
}


  .head-button {
    position: absolute;
    padding: 0px 12px;
    top: 40%;
    right:60px;
    height: 57px;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.944);
    border-radius: 0.4rem;
    font-weight: 500;
    background-color: transparent;
    
  }


  .head-address {
    position: absolute;
    padding: 0px 12px;
    top: -1.2%;
    right:220px;
    height: 55px;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.944);
    border-radius: 0.4rem;
    color: #fff;
    justify-content: center;
    align-items: center;
    line-height: 55px;
    font-weight: 500
  }
  .head-opensea {
    position: absolute;
    top:1.1%;
    right:150px;
    width: 60px;
    height: 57px;
  }
  .head-twitter {
    position: absolute;
    top:1.1%;
    right:80px;
    width: 60px;
    height: 57px;
  }
  
  .head-discord {
    position: absolute;
    top:1.1%;
    right:10px;
    width: 60px;
    height: 57px;
  }
.div-para1 {
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;
    border-radius: 10px;
    border: solid 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40vw;
    max-width: 700px;
}

.div_para2_text {
    background-image: linear-gradient( #000000);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;    border-radius: 10px;
    border: solid 2px #fc9136;
    box-shadow: 0 0 16px 0 #eb5c03;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    height: 370px;
}

.div-para3-text {
    background-image: linear-gradient( #0c0c0c, #d454e2);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;    border-radius: 10px;
    border: solid 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.div-para4-text {
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;    border-radius: 10px;
    border: solid 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}
.div-title {
    padding: 0px 10px;
    margin-top: 10px;
    display: block;
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    font-size: 70px;
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}

.para-container {
    position: relative;
    z-index: -1;
}

.div-content {
    font-family: Sora, sans-serif;
    color: #000000;
    font-size: 1.125rem;
    line-height: 140%;
    font-weight: 300;
    text-align: left;
}

.deposit_button{
    position: relative;
    display: flex;
    width: auto;
    min-height: 8px;
    min-width: 10vw;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254, 143, 45);
    border-radius: 16px;
    background-color: rgba(254, 143, 45);
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.deposit_button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    background-color: rgba(254,143,45);
    box-shadow: 0 0 16px 0 rgba(254,143,45);
}

.withdraw_button {
    position: relative;
    display: flex;
    width: auto;
    min-height: 8px;
    min-width: 10vw;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    border-radius: 16px;
    background-color: transparent;
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: rgba(254,143,45);
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.create_button {
    position: relative;
    display: flex;
    width: auto;
    min-height: 8px;
    min-width: 10vw;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    border-radius: 16px;
    background-color: transparent;
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: rgba(254,143,45);
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.create_button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    box-shadow: 0 0 16px 0 rgba(254,143,45);
}

.reward_button{
    position: relative;
    display: flex;
    width: auto;
    min-height: 8px;
    min-width: 10vw;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(254, 45, 45);
    border-radius: 16px;
    background-color: rgb(254, 45, 45);
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.reward_button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(254, 45, 45);
    box-shadow: 0 0 16px 0 rgb(254, 45, 45);
}

.reward_button_disabled{
    position: relative;
    display: flex;
    width: auto;
    min-height: 8px;
    min-width: 10vw;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(161, 157, 157);
    border-radius: 16px;
    background-color: rgb(161, 157, 157);
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    pointer-events: none;

}
.invite_button {
    position: relative;
    display: flex;
    width: auto;
    min-height: 8px;
    min-width: 10vw;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(45, 101, 254);
    border-radius: 16px;
    background-color: transparent;
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: none;
    background-color: rgb(45, 101, 254);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}


.list_item{
    align-items:flex-end;
    margin-top: 40px;
    display:flex;
    justify-content: space-around;
    height: 40px;
}

.list_my_balance{
    align-items:flex-end;
    display:flex;
    margin-top: 5px;
    justify-content: space-around;
    height: 30px;
    align-items: center;
}



.withdraw_button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    box-shadow: 0 0 16px 0 rgba(254,143,45);
}

.invite_button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(45, 101, 254);
    box-shadow: 0 0 16px 0 rgb(45, 101, 254);
}

.mini_deposit_button{
    position: relative;
    display: flex;
    width: auto;
    min-height: 10px;
    min-width: 100px;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    border-radius: 6px;
    background-color: rgba(254,143,45);
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: #fff;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    margin-top: 1px;
    margin-bottom: 1px;

}

.mini_deposit_button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    box-shadow: 0 0 16px 0 rgba(254,143,45);
}

.mini_withdraw_button {
    position: relative;
    display: flex;
    width: auto;
    min-height: 10px;
    min-width: 100px;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #fe8f2d;
    border-radius: 6px;
    background-color: transparent;
    transition: background-color 300ms ease;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: rgba(254,143,45);
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    margin-top: 1px;
    margin-bottom: 1px;

}

.mini_withdraw_button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(254,143,45);
    box-shadow: 0 0 16px 0 rgba(254,143,45);
}


.button_container {
    display: flex; /* 使用Flex布局 */
    justify-content: center; /* 水平居中对齐 */
    align-items: center; /* 垂直居中对齐 */
    gap: 16px; /* 按钮之间的间隔 */
    max-width: 600px; /* 最大宽度 */
    margin: 0 auto; /* 居中对齐 */
    padding: 16px; /* 内边距 */
  }

  .reward_button_container {
    display: flex; /* 使用Flex布局 */
    justify-content: center; /* 水平居中对齐 */
    align-items: center; /* 垂直居中对齐 */
    gap: 16px; /* 按钮之间的间隔 */
    margin: 0 auto; /* 居中对齐 */
  }


.grid-buddies {
    width: 100%;
    margin-top: -1%;
}

input {
    background: white !important;
    border-radius: 10px;
    text-align: center;
}

.mint-btn {
    margin-top: 0px;
    width: 50%;
    border: 6px solid rgba(0, 0, 0, 0.944);
    border-radius: 0.4rem;
}

.mint-disable-btn {
    margin-top: 0px;
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.944);
    border-radius: 0.4rem;
}

.mint-disable-style {
    margin-top: 0px;
    width: 50%;
}


.mint-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

}

.mint_logo {
    width: 100px;
    position: absolute;
    margin-top: 0px;
    
}

.newlanding-mint-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction:  row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    position: relative;

}


.mint-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.plus-button {
    margin-top: 0px;
    padding: 0px 11px;
    background-color: #5862f5;
}

.max-button {
    margin-top: 0px;
    margin-left: 5px;
    padding: 0px 11px;
    background-color: #5862f5;
}

.mint-pic {
    width: 400px;
}
.mint-instruction {
    width: 700px;
}
.mint_bg1 {
    font-family: All-round-gothic;
    font-size: 20px;
    background-color: rgb(18, 18, 18);
    color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    width: 100%;
    overflow-x: auto;
    padding: 0.75rem;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
    height:200px;
    width: 90% !important; 
    border-color: var(--Highlight,#00ecd5);
    border-style: solid;
    border-width: 1px;


}
.mint_bg1_mobile {
    display: none;
}
.divider{
    background-color: rgb(255, 255, 255);
    width: 95%;
}

.name_style {
    display: block;
    color: var(--main-color) !important;
    font-family: RamblaBold;
    font-size: 2.5vw;
}

.div_style {
    display: block;
    color: var(--main-color) !important;
}
.list_style{
    width: 100%;
    color: #FFFFFF;
    background-color: 'background.paper';
}
.listItem_style {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 8px;
    padding: 12px 32px;
    background-color: transparent;
    font-family: Sora, sans-serif;
    text-shadow: none;
    text-align: center;
    text-decoration: none;
   text-transform: none;
}

.reward_button {
    position: relative;
    display: flex;
    width: auto;
    height: auto;
    min-height: 40px;
    width:30%;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #F7931B;
    border-radius: 16px;
    background-color: #F7931B;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: rgb(0, 0, 0);
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    text-transform:none;
}
.mint_inscription {
    position: relative;
    display: flex;
    width: auto;
    height: auto;
    min-height: 40px;
    width:30%;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #e5573e;
    border-radius: 16px;
    background-color: #e5573e;
    font-family: Sora, sans-serif;
    text-shadow: none;
    color: rgb(0, 0, 0);
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    text-transform:none;
}

.list_item{
    align-items:center;
    margin-top: 5px;
    display:flex;
    justify-content: space-around;
    height: 40px;
}

.list_my_balance{
    align-items:flex-end;
    display:flex;
    margin-top: 5px;
    justify-content: space-around;
    height: 30px;
    align-items: center;
}

.reward_button:hover {
    border-style: solid;
    border-width: 2px;
    border-color: #F7931B;
    box-shadow: 0 0 16px 0 #F7931B;
}
.mint_inscription:hover {
    border-style: solid;
    border-width: 2px;
    border-color: #e5573e;
    box-shadow: 0 0 16px 0 #e5573e;
}

.percent_container{
    display: flex; /* 使用Flex布局 */
    justify-content: flex-end; /* 水平居中对齐 */
    align-items: center; /* 垂直居中对齐 */
    gap: 6px; /* 按钮之间的间隔 */
    margin: 0 auto; /* 居中对齐 */
    margin-top: 2px;
    width: 90%;
    margin: 20px;
  }

  .percent_button {
    position: relative;
    display: flex;
    min-height: 0.5px;
    min-width: 0.5px;
    margin: 0.5px; 
    padding: 0.5px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1.5px;
    border-color: #F7931B;;
    border-radius: 16px;
    background-color: transparent;
    color: #F7931B;;
    font-size: 0.1px !important;
    text-size-adjust: 0.1px;
    text-shadow: none;
    text-align: center;
    text-decoration: none;
   text-transform: none;
  }
  .percent_button:hover {
    border-style: solid;
    border-width: 1.5px;
    border-color: #bfd7ee;
    color: #bfd7ee;

}

.reward_font{
    color:var(--main-color) !important;
    font-size: 10px !important;
    font-weight: 600;
    text-transform: none; 
    font-family: All-round-gothic;
}

.balance_top_font{
    color:rgb(0, 0, 0);
    font-size: 1vw !important;
    font-weight: 900;
}
.youtube{
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
    width: 90%;
}

.mint_bg2 {
    font-family: All-round-gothic;
    font-size: 15px;
    background-color: #faf9f9;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;;
    display: flex;
    border-radius: 50px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: -400px;
    margin-bottom: 80px;
    height:580px;
    width: 90%;
    box-shadow: 0 0 16px 0 #eb5c03;
}

.slider_box{
margin-top: 20px;
width:80%;
margin: 0 auto; /* 居中对齐 */
}

.slider{
    margin-top: 20px;
    display: flex; /* 使用Flex布局 */
    justify-content: center; /* 水平居中对齐 */
    align-items: center; /* 垂直居中对齐 */
    color: #5862f5;
}
.plus_button {
    margin-top: 0px;
    padding: 0px 11px;
    background-color: #5862f5;
}

.buy_button {
    margin-top: 0px;
    padding: 0px 11px;
    background-color: #f55858;
}

.max_button {
    margin-top: 0px;
    margin-left: 5px;
    padding: 0px 11px;
    background-color: #5862f5;
}

.dialog{
    display: flex; /* 使用Flex布局 */
    justify-content: center; /* 水平居中对齐 */
    align-items: center; /* 垂直居中对齐 */
}


.dialog_title {
    font-family: Sora;
    background-color: #000000;
    color:#FFFFFF ;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

.dialog_action {
    font-family: Sora;
    font-size: 25px;
    background-color: #000000;
    color:#FFFFFF ;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.dialog_content {
    font-family: Sora;
    font-size: 25px;
    display: flex;
    color:#FFFFFF ;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
}

.invite_content {
    font-family: Sora;
    font-size: 10px;
    display: flex;
    color:#FFFFFF ;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    width:90%;
}
.deposit_balance{
    cursor: pointer;
    font-size: 8px;
  }

  .input_container {
    position: flex;
    background-color: white;
    width: 100%;
    border-radius: 5px;
    border-width: 2px;
    border-color: rgba(254,143,45);;
    border-radius: 16px;
  }


.deposit_input{
    font-size: 20px;
    width:98%;
    border: none;
    outline: none;
    background-color: transparent;
    text-align: right;
  }

  .invite_input{
    font-size: 10px;
    width:98%;
    height:30px;
    border: none;
    outline: none;
    background-color: transparent;
    text-align: right;
  }

  .percent_container{
    display: flex; /* 使用Flex布局 */
    justify-content: flex-end; /* 水平居中对齐 */
    align-items: center; /* 垂直居中对齐 */
    gap: 6px; /* 按钮之间的间隔 */
    margin: 0 auto; /* 居中对齐 */
    margin-top: 2px;
    width: 90%;
    margin: 20px;
  }

  .percent_button {
    position: relative;
    display: flex;
    min-height: 0.5px;
    min-width: 0.5px;
    margin: 0.5px; 
    padding: 0.5px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1.5px;
    border-color: rgba(254,143,45);;
    border-radius: 16px;
    background-color: transparent;
    color: rgba(254,143,45);;
    font-size: 0.1px !important;
    text-size-adjust: 0.1px;
    text-shadow: none;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }
  .percent_button:hover {
    border-style: solid;
    border-width: 1.5px;
    border-color: #bfd7ee;
    color: #bfd7ee;

}

.tutial_font{
    color:rgb(255,255,255);
    font-size: 1.5vw !important;
    font-weight: 900;
}


.address_font{
    color:rgb(0, 0, 0);
    font-size: 0.8vw;
    font-weight: 50;
    display:flex;
    justify-content: center;
    align-items: center;
}

.fee_font{
    color:rgb(0, 0, 0);
    font-size: 10px;
    font-weight: 50;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.deployed_token{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.renounce_ownership{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.renounce_ownership_select{
    color:rgb(0, 0, 0);
}

.create_token{
    margin-top: 30px;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
}

.deployed_token_mobile{
    display: none;
}

.balance_top_font{
    color:rgb(0, 0, 0);
    font-size: 1vw !important;
    font-weight: 900;
}

.deposit_font{
    color:rgb(0, 0, 0);
    font-size: 25;
    font-Weight: '600';
    letter-spacing: 0
}


.wallet-bg {
    position: relative;
    padding-top: 4%;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.wallet-img {
    max-width: 75% !important;
}

.connect-wallet-btn {
    width: 175px;
    position: absolute;
    margin-right: 8.7%;
}

.div-para2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 75vw;
}

.div-para3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 75vw;
    margin-top: -16%;
}

.twitter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.twitter-img {
    width: 300px;
}

.twitter-img-mobile {
    display: none;
}

.accessory-img_1 {
    width: 370px;
    height: 370px;
    border-radius: 10px;
    border: solid 1px black;
}

.accessory-img_2 {
    width: 370px;
    height: 370px;
    border-radius: 10px;
    border: solid 1px black;
}

.twitter-btn {
    background-color: #1d2ad5;
    margin-top: 0px;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: midnight;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0px;
    color: #f1f1f1;
    /* Icon */
}

.tab-label::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all .35s;
}

.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: midnight;
    transition: all .35s;
    box-sizing: border-box;
    color: #f1f1f1;

}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: midnight;
    cursor: pointer;
}

.tab {
    width: 60vw;
    color: #000;
    font-family: All-round-gothic;
    font-size: 18px;
    overflow: hidden;
    border: solid var(--main-color) 2px;
    margin-top: 20px;
}

.tab-content {
    font-family: rambla;
}

.footer-style {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 95vw;
    height: 50px;
    display: flex;
    justify-content: space-between;
    /*height: 495px;*/
    /*height: 95vw;*/
}
.footer-style-mobile {
  display:none
}

.footer-mobile {
    margin-top: 50px;
    width: 95vw;
    /*height: 495px;*/
    display: none;
}

.footer-media {
    width: 17vw;
    display: flex;
    justify-content: space-between;
    /*height: 95vw;*/
}

.footer-section-title {
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    width: 20vw;
    height: 100px;
}

.footer-section-title-media {
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    margin-right: 15px!important;
}

input[type=checkbox] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

input:checked ~ .tab-content {
    border-top: solid var(--main-color) 2px;
    max-height: 100vh;
    padding: 1em;
}

.mint_font{
    color:rgb(209, 205, 205);
}

.balance_font{
    color:rgb(209, 205, 205);
}

.airdrop_title_font{
    color:rgb(245, 119, 117);
    font-family: AREMBO;
    font-size: 4vw;
}

.comming_title_font{
    color:rgb(245, 119, 117);
    margin-left: 200px;
    font-family: AREMBO;
    font-size: 2vw;
}

.max{
    cursor: pointer !important;
    color:rgb(0, 0, 0);

}

input:checked + .tab-label {
}

input:checked + .tab-label::after {
    transform: rotate(90deg);
}

.mobile-img-container {
    display: none;
}

.img-flyingbuddies-mobile {
    display: none;
}

.grid-buddies-mobile{
    display: none;
}

.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 7px;
    font-size: 18px;
    border: none;
    cursor: pointer;
}



  .media_image {
    margin-left: 0px;
    flex:1;
    filter: opacity(0.8);
    cursor: pointer; /* 鼠标指针移上去显示为“小手” */
    width: 40px;
    height:40px;
  }
  
  .media_image:hover{
    transform: scale(1.08);
    filter: opacity(1);
  
  }

  .liz_header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100px;
    z-index:5;
    background-color: rgba(27, 34, 38, 0.9);
}

  .affix {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    z-index:5;
    background-color: rgba(27, 34, 38, 0.9);
}
.liz_menu_sect {
    position: absolute;
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 580px;
    left: 30%;
}





.liz_menu_list{
    font-family: Prototype;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: rgba(255, 255, 255, 0.8);
    text-transform: capitalize;
}

.liz_menu_right_sect{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.liz_menu_list ul{
    margin-left: 65px;
    margin-right: 96px;
    min-width: 409px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
}
.liz_menu_list ul li{
    position: relative;
    cursor: pointer;
}


.liz_menu_list ul li a {
    font-family: Prototype;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media only screen and (max-width:1730px) {
    /* .btn-connect {
        margin-right: 120px;
    } */
    /* .btn-icon {
        display: none;
    } */
    /* .nav-container {
        width: auto;
        padding-left: 30px;
        margin-left: 50px;
    }
    .navbar-nav>li>a {
        margin-left: 0px !important;
    } */
}

@media only screen and (max-width:1600px) {
    /* .btn-icon {
        display: none;
    } */
}

@media only screen and (max-width:1420px) {


}

@media only screen and (max-width:1460px) {
    /* .btn-icon {
        display: none;
    }
    #myNavbar1 {
        display: none;
    }
    .responsive {
        display: inline-block!important;
    }
    .btn-connect {
        float: right;
        margin-right: 120px;
    }
    .navbar-brand{
        margin-left: 4vw !important;
    }
    .navbar-toggle {
        display: block;
        border-radius: 100px;
        padding: 15px;
        margin-top: 45px;
        float: right;
        position: absolute;
        right: 20px;
    }
    .navbar-toggle .icon-bar {
        display: block;
        width: 25px;
        height: 5px;
        border-radius: 20px;
    }
    #myNavbar1 {
        position: absolute;
        right: 20px;
        margin-top: 95px;
    }
    .nav-container {
        display: flex;
        border-radius: 3px;
        border: solid 0.2px #666666;
        margin-left: 70px;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;
        margin-right: -30px;
        padding-right: 10px;
        padding-left: 0px;
        width: 400px;
    }
    .navbar-collapse1 {
        border-top: 0px !important;
    } */
}

/* @media only screen and (max-width:600px) {
    #myNavbar1 {
        display: none;
    }
    #logotext {
        display: none;
    }
    .navbar-toggle {
        display: none;
    }
    .btn-connect {
        margin-right: 4vw;
    }
} */

/* @media only screen and (max-width:540px) {
    .btn-connect {
        font-size: 22px;
        margin-top: 49px;
    }
    .navbar-toggle {
        right: 0px;
    }
    .btn-connect {
        margin-right: 80px;
    }
    .logo-text-wrapper {
        display: none;
    }
    .star-btn-container {
        margin-top: 0px;;
    }
    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }
      .head-button {
        position: absolute;
        padding: 0px 12px;
        top: 5%;
        right:3%;
        height: 57px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        font-weight: 500;
        background-color: transparent;
        
      }
    
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:3%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
} */

/* @media only screen and (max-width:1200) {
    .btn-grab {
        width: 70%;
        padding: 10px 30px;
    }
    .slider-container-div {
        margin-top: -14%;
        margin-bottom: -10%;
    }
    .footer {
        display: none;
    }
    .footer-mobile {
        display: block;
    }
    .main-section-title{
        display: none;
    }
    .main-section-title-mobile {
        display: block;
    }
    .main-section-subtitle-mobile {
        display: block;
    }
    .main-section-img-container {
        display: none;
    }
    .mobile-img-container{
        width: 90vw;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
    .mobile-img-container img {
        width: 33vw;
    }
    .mobile-img-container img:nth-child(2) {
        width: 50vw;
        margin-left: -15vw;
        margin-right: -15vw;
        margin-top: 10%;
        position: relative;
        z-index: 11;
    }
    .mint-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .wallet-bg {
        align-items: center;
    }
    .connect-wallet-btn {
        margin-right: -7%;
        margin-top: 55%;
    }
    .star-btn-container {
        margin-left: 20%;
    }
    .mint-bg {
        font-size: 15px;
    }
    .img-flyingbuddies {
        display: none;
    }

    .div-para1 {
        width: 70vw;
        margin-top: 60px;
        margin-right: 10vw;
    }


    .div-para2 {
        flex-direction: column-reverse;
    }


    .div-para2-text {
        width: 70%;
    }

    .twitter-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .twitter-img {
        display: none;
    }

    .twitter-btn {
        background-color: #4853ee;

    }

    .twitter-img-mobile {
        display: block;
        width: 12vw;
        margin-right: 40vw;
        margin-top: -4%;
        margin-bottom: -10%;
        z-index: 22;
        pointer-events: none;
    }



    .accessory-img_1 {
        margin-top: 20px;
        width: 200px;
        height: 200px;
        display: none;
    }

    .accessory-img_2 {
        margin-top: 80px;
        width: 200px;
        height: 200px;
    }

    .tab {
        width: 90vw;
    }

    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }
      .head-button {
        position: absolute;
        padding: 0px 12px;
        top: 5%;
        right:3%;
        height: 57px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        font-weight: 500;
        background-color: transparent;
        
      }
    
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:3%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
} */

@media only screen and (max-width:1180px) {
    .mint-instruction {
        width: 90%;
    }
    .main-section-title{
        display: none;
    }
    .main-section-title-mobile {
        display: block;
    }
    .main-section-subtitle-mobile {
        display: block;
    }
    .main-section-img-container {
        display: none;
    }
    .div-content {
        font-size: 3vw;
    }
    .reward_font{
        color:var(--main-color) !important;
        font-size: 4vw !important;
        font-weight: 1100;
    }

    .address_font{
        font-size: 10px;
    }
    .airdrop_title_font
    {
        font-size: 10vw;
    }
    .comming_title_font{
        margin-left: 100px;
        font-size: 6vw;
    }
    .nav-container {
        width: 30px;
    }
    .send_box{
        width: 50%;
    }
    .receive_box{
        width: 50%;
    }
    .balance_box{
        width: 49%;
    }
    .bridgetablepanel{
        width:110%;
    }
    .twitter-img-mobile {
        display: block;
        width: 27vw;
        margin-right: 57vw;
        margin-top: -6%;
        margin-bottom: -21%;
        z-index: 22;
        pointer-events: none;
    }
    .div-para3 {
        margin-top:-100px;
        align-items: center;
    }
    .div-para2-text {
        background-image: linear-gradient( #0c0c0c, #d454e2);
        margin-top:20px;
        width: 80%;
    }
    .div-para3-text {
        background-image: linear-gradient( #0c0c0c, #d454e2);
        margin-top:20px;
        width: 80%;
    }
    .div-para4-text {
        margin-top:20px;
        width: 80%;
    }
    .div-para2 {
        flex-direction: column-reverse;
    }
    .slider-container-div {
        margin-top: -160px;
        margin-bottom: 0px;
    }
    .btn-grab {
        padding: 10px 30px;
    }
    .main-section-title-mobile {
        font-size: 22vw;
        margin-top: 120px;
    }
    .main-section-subtitle-mobile{
        font-size: 16vw;
        margin-top: 0%;
    }
    .navbar-brand {
        margin-left: 2vw !important;
    }
    .balance_top_font{
        color:rgb(0, 0, 0);
        font-size: 3vw !important;
        font-weight: 900;
    }
    .nav-container {
        background-color: var(--background-color) !important;
        display: flex;
        border-radius: 3px;
        border: solid 0.2px #666666;
        margin-left: 70px;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -45px;
        margin-right: 10px;
        padding-right: 10px;
        padding-left: 0px;
    }

    .mint_bg1 {
        height:200px;
        width: 94%;
    }
    .youtube{
        width: 94%;
    }
    .mint_logo{
        width: 80px;
        margin-top: 10px;
    }
    .star-btn-container {
        padding: 10vw;
    }
    .btn-grab {
        font-size: 5vw;
    }
    .div-para1{
        width: 60vw;
    }
    .div-title {
        font-size: 10vw;
    }
    .div-content {
        padding: 10px 30px;
        padding-bottom: 30px;
    }
    .grid-buddies {
        margin-top: -230px;
    }
    .head-section-img {
        display: block;
        margin-left: 4%;
        margin-top: 6%;
        height:80px;
        width:80px;
    }
    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }
      .head-button {
        position: absolute;
        padding: 0px 12px;
        top: 40%;
        right:60px;
        height: 57px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        font-weight: 500;
        background-color: transparent;
        
      }

      .list_my_balance{
        align-items:flex-end;
        display:flex;
        margin-top: 10px;
        justify-content: space-between;
        height: 30px;
        align-items: center;
    }

      .deposit_button{
        position: relative;
        display: flex;
        width: auto;
        padding: 6px 8px;
    }



    .withdraw_button{
        position: relative;
        display: flex;
        width: auto;
        padding: 6px 8px;
    }

    .reward_button{
        position: relative;
        display: flex;
        width: auto;
        padding: 6px 8px;
    }
    .reward_button_disabled{
        position: relative;
        display: flex;
        width: auto;
        padding: 6px 8px;
    }

    .invite_button{
        position: relative;
        display: flex;
        width: auto;
        padding: 6px 8px;

    }
    
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:10%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }

    .deployed_token{
        display: none;
    }

    .deployed_token_mobile{
        display: flex;
        justify-content: center;
    }
      .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 50px;
        display: block;
        color: var(--main-color) !important;
        font-family: All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 60px!important;
        margin-right: 15px!important;
        width: 192px!important;
        vertical-align: middle;
    
    }
    
    .slider-container-div {
    display: none;
     }
     .flex_container_partners {
        display: none;
       
       }


       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family: All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    
    .footer-section-title-media-mobile {
        color: var(--main-color) !important;
        font-family: All-round-gothic;
        margin-right: 15px!important;
    }
    
    .footer-media-mobile {
        width: 105vw;
        display: flex;
        justify-content: center;
        height: 40px;
    
        /*height: 95vw;*/
    }
    
    .slider-container-div {
    display: none;
     }

    .img {
        display: inline-block;
        width:20px;
        height:20px;
        vertical-align: middle;
    }

    .footer-style {
     display:none;
    }
    .footer-style-mobile {
        margin-top: 50px;
        display: inline-block;
        text-align:center;       
     }
}

@media only screen and (max-width:767px) {
    .mint-instruction {
        width: 90%;
    }
    .div-content {
        font-size: 3vw;
    }
    .nav-container {
        width: 310px;
    }
    .twitter-img-mobile {
        display: block;
        width: 27vw;
        margin-right: 57vw;
        margin-top: -6%;
        margin-bottom: -21%;
        z-index: 22;
        pointer-events: none;
    }
    .div-para3 {
        margin-top:-100px;
        align-items: center;
    }

    .div-para3-text {
        margin-top:20px;
        width: 80%;
    }
    .div-para4-text {
        margin-top:20px;
        width: 80%;
    }

    .slider-container-div {
        margin-top: -160px;
        margin-bottom: 0px;
    }
    .btn-grab {
        padding: 10px 30px;
    }
    .main-section-title-mobile {
        font-size: 22vw;
        margin-top: 120px;
    }
    .main-section-subtitle-mobile{
        font-size: 16vw;
        margin-top: 0%;
    }
    .navbar-brand {
        margin-left: 2vw !important;
    }
    .nav-container {
        background-color: var(--background-color) !important;
        display: flex;
        border-radius: 3px;
        border: solid 0.2px #666666;
        margin-left: 70px;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -45px;
        margin-right: 10px;
        padding-right: 10px;
        padding-left: 0px;
    }
    .star-btn-container {
        padding: 10vw;
    }
    .btn-grab {
        font-size: 5vw;
    }
    .div-para1{
        width: 60vw;
    }
    .div-title {
        font-size: 10vw;
    }
    .div-content {
        padding: 10px 30px;
        padding-bottom: 30px;
    }
    .grid-buddies {
        margin-top: -230px;
    }
    .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family: All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    .mint_bg1 {
     display: none;
    }

    .mint_bg1_mobile {
        font-family: All-round-gothic;
        font-size: 20px;
        background-color: rgb(18, 18, 18);
        color: rgb(255, 255, 255);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
        width: 96%;
        overflow-x: auto;
        padding: 0.75rem;
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important ;;
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        margin-top: 50px;
        margin-bottom: 20px;
        height:370px;
        width: 90% !important; 
        border-color: var(--Highlight,#00ecd5);
        border-style: solid;
        border-width: 1px;
       }
    .slider-container-div {
    display: none;
     }
    .head-section-img {
        display: block;
        margin-left: 4%;
        margin-top: 6%;
        height:80px;
        width:80px;
    }
    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }
      .head-button {
        position: absolute;
        padding: 0px 12px;
        top: 40%;
        right:60px;
        height: 57px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        font-weight: 500;
        background-color: transparent;
        
      }
      .deposit_button1{
        position: relative;
        display: flex;
        width: auto;
        padding: 5px 2px;
        min-height: 8px;
        min-width: 100px;
    }

    .withdraw_button1{
        position: relative;
        display: flex;
        width: auto;
        padding: 5px 2px;
        min-height: 8px;
        min-width: 100px;
    }

    .invite_button1{
        position: relative;
        display: flex;
        width: auto;
        padding: 5px 2px;
        min-height: 8px;
        min-width: 100px;
    }
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:10%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
      .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family: All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    .footer-section-title-media-mobile {
        color: var(--main-color) !important;
        font-family: All-round-gothic;
        margin-right: 15px!important;
    }
    
    .footer-media-mobile {
        width: 105vw;
        display: flex;
        justify-content: center;
        height: 40px;
    
        /*height: 95vw;*/
    }
    
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    
    .slider-container-div {
    display: none;
     }

    .img {
        display: inline-block;
        width:20px;
        height:20px;
        vertical-align: middle;
    }

    .footer-style {
     display:none;
    }
    .footer-style-mobile {
        margin-top: 50px;
        display: inline-block;
        text-align:center;       
     }
      
}

@media only screen and (max-width:400px) {
    .mint-instruction {
        width: 90%;
    }
    .div-content {
        font-size: 3vw;
    }
    .nav-container {
        width: 310px;
    }
    .twitter-img-mobile {
        display: block;
        width: 27vw;
        margin-right: 57vw;
        margin-top: -6%;
        margin-bottom: -21%;
        z-index: 22;
        pointer-events: none;
    }
    .div-para3 {
        margin-top:-100px;
        align-items: center;
    }
    .div-para2-text {
        margin-top:20px;
        width: 80%;
    }
    .div-para3-text {
        margin-top:20px;
        width: 80%;
    }
    .div-para4-text {
        margin-top:20px;
        width: 80%;
    }
    .div-para2 {
        align-items: center;

    }
    .slider-container-div {
        margin-top: -160px;
        margin-bottom: 0px;
    }
    .btn-grab {
        padding: 10px 30px;
    }
    .main-section-title-mobile {
        font-size: 22vw;
        margin-top: 120px;
    }
    .main-section-subtitle-mobile{
        font-size: 16vw;
        margin-top: 0%;
    }
    .navbar-brand {
        margin-left: 2vw !important;
    }
    .nav-container {
        background-color: var(--background-color) !important;
        display: flex;
        border-radius: 3px;
        border: solid 0.2px #666666;
        margin-left: 70px;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -45px;
        margin-right: 10px;
        padding-right: 10px;
        padding-left: 0px;
    }
    .star-btn-container {
        padding: 10vw;
    }
    .btn-grab {
        font-size: 5vw;
    }
    .div-para1{
        width: 60vw;
    }
    .div-title {
        font-size: 10vw;
    }
    .div-content {
        padding: 10px 30px;
        padding-bottom: 30px;
    }
    .grid-buddies {
        margin-top: -230px;
    }
    .head-section-img {
        display: block;
        margin-left: 4%;
        margin-top: 2%;
        height:80px;
        width:80px;
    }

    .accessory-img {
        margin-top: 20%;
        width: 50vw;
        height:50vw;
    }

    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }
      .head-button {
        position: absolute;
        padding: 0px 12px;
        top: 40%;
        right:60px;
        height: 57px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        font-weight: 500;
        background-color: transparent;
        
      }
    
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:5%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
      .flex_container_partners {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family: All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    
    .slider-container-div {
    display: none;
     }

    .img {
        display: inline-block;
        width:20px;
        height:20px;
        vertical-align: middle;
    }

    .footer-style {
     display:none;
    }
    .footer-style-mobile {
        margin-top: 50px;
        display: inline-block;
        text-align:center;       
     }
}
.footer-section-title-mobile {
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    margin-top: 50px;
    width: 100vw;
    height: 40px;
}
.footer-section-title-media-mobile {
    color: var(--main-color) !important;
    font-family: All-round-gothic;
    margin-right: 15px!important;
}

.footer-media-mobile {
    width: 105vw;
    display: flex;
    justify-content: center;
    height: 40px;

    /*height: 95vw;*/
}

.progress-bar-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    font-size: 0.5em;
}
.progress-bar {
    border: lightgray solid 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: content-box;
    position: relative;
    margin: 1em;
    border-radius: 1em;
    justify-content: flex-start;
    height: 30px;
    width: 100%;
    padding: 0.5em;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
}
.progress-bar-fill {
    display: block;
    border-radius: 0.8em;
    background-image: linear-gradient(111deg, #00ffc1, #9400fd);
    position: relative;
    overflow: hidden;
}
.supply-minted {
    width: 100%;
    position: absolute;
    font-family: All-round-gothic;
    font-size: 22px;
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}
