.home-container {
    width: 100%;
    padding-right: 0px !important;
}

button:active {
    transform:scale(98%);
}

.btn-icon img:hover {
    filter: brightness(0.85);
}

.navbar-collapse1 {
    float: left !important;
}

.logo-text-wrapper {
    display: inline-block;
}

input:disabled {
    border:solid 2px black;
    background-color:#ffffff;
    color:black;
    opacity: 1; /* required on iOS */
}

.navbar {
    background-color: var(--main-color);
    height: 125px;
    border-radius: 0 !important;
    filter: drop-shadow(1px 1px 1px rgb(117, 117, 117));
    margin-bottom: 0px !important;
    width: 100vw;
    z-index: 111;
    display: none;
}

.navbar-brand {
    margin-left: 80px !important;
}

.nav-container {
    padding-left: 100px;
    padding-right: 50px;
    border-radius: 50px;
    border: solid 2px black;
    filter: drop-shadow(1px 2px 3px rgb(65, 65, 65));
    margin-top: 45px;
    margin-left: 70px;
    width: 780px;
}

.nav-btn-group {
    margin-left: 50px;
    margin-top: 64px;
    float: right;
}

.nav-btn-group .btn-icon {
    border-radius: 0px;
    border: none;
}

.button {
    margin-top: 10px;
}

.btn-connect {
    position: absolute !important;
    padding: 5px 20px;
    background-color: #4651ea;
    font-size: 26px;
    -webkit-box-shadow: inset 1px 1px 1px rgb(255, 255, 255), inset -1px -1px 1px rgb(77, 64, 72);
    margin-top: 46px;
    float: right;
    right: 0px;
    margin-right: 125px;
}

.slider-container-div {
    width: 100vw;
    justify-content: center;

    /* overflow: hidden; */
}

.slider-mask {
    overflow: hidden;
    justify-content: center;

}

.slider-container {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;

    /* overflow: hidden;     */
}

.secondary {
    margin-top: 100px;
    justify-content: center;

}

.slider-container li {

}

.flex_container_partners-mobile{
    display: none;
}

.slider-container1 li img {
    margin: 15px;
    width: 300px;
    border-radius: 15px;
    border: solid white 15px;
    box-sizing: content-box;
    transform: rotate(356deg);
}



.slider-container li img {
    margin: 15px;
    width: 300px;
    border-radius: 15px;
    border: solid white 15px;
    box-sizing: content-box;
}

.ranking-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .ranking-table th,
  .ranking-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .ranking-rank {
    position: relative;
    text-align: center;
    width: 50px;
  }
  
  .ranking-rank-box {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
  }
  
  .ranking-rank-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-shadow: 2px 2px #000;
  }
  
  .ranking-rank-box:before,
  .ranking-rank-box:after {
    content: "";
    position: absolute;
    z-index: -1;
  }
  
  .ranking-rank-box:before {
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  
  .ranking-rank-box:after {
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: #ddd;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

.slider-container li:nth-child(1) img {
}

.slider-container li:nth-child(2) img {
}

.slider-container li:nth-child(3) img {
}

.slider-container li:nth-child(4) img {
}


.flex_container_partners {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    width: 100%;
    justify-content: center;

}

.flex_container_partners img {
    display: inline-block;
    height: 72px!important;
    margin-right: 15px!important;
    width: 244px!important;
    vertical-align: middle;

}

.img {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
}

.star-btn-container {
    margin-top: 50px;
    padding: 100px;
    margin-bottom: 2%;
}

.btn-grab {
    padding: 15px 70px;
    font-size: 40px;
    background-color: #4853eb;
    -webkit-box-shadow: inset 1px 1px 1px rgb(255, 255, 255), inset -1px -1px 1px rgb(77, 64, 72);
    float: right;
    right: 0px;
    border-radius: 50px;
    margin-top: 20px;
    position: relative;
    z-index: 11;
}

.nav-btn-group img {
    height: 50px;
}

.logo {
    display: inline-block !important;
    margin-left:1%;
    filter: drop-shadow(1px 1px 1px rgb(31, 31, 31));
}

.logo-text {
    display: inline-block !important;
    height: 100px;
    image-rendering: pixelated;
    filter: drop-shadow(1px 1px 1px rgb(27, 27, 27));
}

.nav-title {
    color: #fff !important;
    font-family: SWEETGIRL;
    font-size: 36px;

}

.main-section-title {
    margin-top: 100px;
    display: block;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    font-size: 6.5vw;
    z-index: 1;
}

.main-section-title-mobile {
    display: none;
}

.main-section-subtitle-title {
    margin-top: 40px;
    display: block;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    font-size: 3.5vw;
    z-index: -1;
}

.mint_logo{
    width: 100px;
}

.main-section-email {
    margin-top: 100px;
    display: block;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    color: var(--main-color) !important;

    font-size: 1.5vw;
    z-index: -1;


}

.head-section-title {
    display: block;
    margin-left: 1%;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    font-size: 2.5vw;

}

.head-section-title-mobile {
    letter-spacing: 5px;
    display: none;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    font-size: 50px;

}


.main-section-subtitle-mobile {
    letter-spacing: 5px;
    margin-top: -20px;
    display: none;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    font-size: 95px;
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}

.main-question-title {
    margin-top: 80px;
    display: block;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    font-size: 6vw;
    text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}

.main-parnters-title {
    margin-top: 80px;
    display: block;
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    font-size: 3vw;
    text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}

.main-section-img-container {
    width: 90vw;
    margin-top: 15px;
}

.nav-title:hover {
    color: #fddddd !important;
    font-family:  All-round-gothic;
    font-size: 36px;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.main-section {
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#myNavbar1 {
    display: inline-block;
}

.img-flyingbuddies {
    width: 13vw;
    margin-left: -13%;
    margin-bottom: 25%;

}
.home-head-wrapper1 {
     width: 100%;
     height: 70px;
  }

  .home-head-wrapper {
  display:grid;
  grid-template-columns:repeat(auto-fit,minmax(200px,1fr));
  gap:10px;
 }

.foot{
display: flex;
    justify-content: space-between;
}

  .head-address {
    position: absolute;
    padding: 0px 12px;
    top: -1.2%;
    right:220px;
    height: 55px;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.944);
    border-radius: 0.4rem;
    color: #fff;
    justify-content: center;
    align-items: center;
    line-height: 55px;
    font-weight: 500
  }
  .head-opensea {
    position: absolute;
    top:1.1%;
    right:150px;
    width: 60px;
    height: 57px;
  }
  .head-twitter {
    position: absolute;
    top:1.1%;
    right:80px;
    width: 60px;
    height: 57px;
  }
  
  .head-discord {
    position: absolute;
    top:1.1%;
    right:10px;
    width: 60px;
    height: 57px;
  }
.div-para1 {
    background-image: linear-gradient( #0c0c0c, #d454e2);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;
    border-radius: 10px;
    border: solid 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40vw;
    max-width: 700px;
}

.div-para2-text {
    background-image: linear-gradient( #000000);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;    border-radius: 10px;
    border: solid 2px #fc9136;
    box-shadow: 0 0 16px 0 #eb5c03;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    height: 370px;
}

.div-para3-text {
    background-image: linear-gradient( #000000);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;    border-radius: 10px;
    border: solid 2px #fc9136;
    box-shadow: 0 0 16px 0 #eb5c03;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    height: 370px;
}

.div-para4-text {
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;    border-radius: 10px;
    border: solid 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    height: 370px;
}
.div-title {
    font-family:  All-round-gothic, sans-serif;
    color: #eb5c03;
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: -0.02em;
}

.para-container {
    /* margin-top: 150px;
    position: relative;
    z-index: 2; */

}

.div-content {
    padding: 0px 40px;
    font-family:  All-round-gothic;
    font-size: 20px;
    color:var(--main-color) !important;
}

.div_para2_text {
    background-image: linear-gradient( #000000);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;    border-radius: 10px;
    border: solid 2px #fc9136;
    box-shadow: 0 0 16px 0 #eb5c03;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    height: 370px;
    margin-top: 20px;
}

.grid-buddies {
    width: 100%;
    margin-top: -1%;
}

input {
    background: white !important;
    border-radius: 10px;
    text-align: center;
}

.mint-btn {
    margin-top: 0px;
    width: 50%;
    border: 6px solid rgba(0, 0, 0, 0.944);
    border-radius: 0.4rem;
}

.mint-disable-btn {
    margin-top: 0px;
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.944);
    border-radius: 0.4rem;
}

.mint-disable-style {
    margin-top: 0px;
    width: 50%;
}


.mint-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

}

.mint-logo {
    width: 100px;
    position: absolute;
    
}

.newlanding-mint-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction:  row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    position: relative;

}


.mint-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
}

.plus-button {
    margin-top: 0px;
    padding: 0px 11px;
    background-color: #5862f5;
}

.max-button {
    margin-top: 0px;
    margin-left: 5px;
    padding: 0px 11px;
    background-color: #5862f5;
}

.mint-pic {
    width: 400px;
}

.mint-instruction {
    width: 98%;

}
.mint-bg {
    font-family:  All-round-gothic;
    font-size: 25px;
    background-image: linear-gradient( #3a3a3a, #292929);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important ;;
    display: flex;
    border-radius: 10px;
    border: solid 2px black;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 80px;
    margin-top: 50px;
    height:500px;
}

.wallet-bg {
    position: relative;
    padding-top: 4%;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.wallet-img {
    max-width: 75% !important;
}

.connect-wallet-btn {
    width: 175px;
    position: absolute;
    margin-right: 8.7%;
}

.div-para2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 75vw;
}

.div-para3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
    width: 75vw;
}

.twitter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.twitter-img {
    width: 300px;
}

.twitter-img-mobile {
    display: none;
}

.accessory-img_1 {
    width: 370px;
    height: 370px;
}
.accessory-img {
    margin-top: -480px;
    width: 100%;
}

.accessory-img_2 {
    width: 370px;
    height: 370px;
    border-radius: 10px;
    border: solid 2px #fc9136;
    box-shadow: 0 0 16px 0 #eb5c03;
}

.twitter-btn {
    background-color: #1d2ad5;
    margin-top: 0px;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: midnight;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0px;
    color: #f1f1f1;
    /* Icon */
}

.tab-label::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all .35s;
}

.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: midnight;
    transition: all .35s;
    box-sizing: border-box;
    color: #f1f1f1;

}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: midnight;
    cursor: pointer;
}

.tab {
    width: 60vw;
    color: #000;
    font-family:  All-round-gothic;
    font-size: 18px;
    overflow: hidden;
    border: solid var(--main-color) 2px;
    margin-top: 20px;
}

.tab-content {
    font-family: rambla;
}

.footer-style {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 95vw;
    height: 50px;
    display: flex;
    justify-content: space-between;
    /*height: 495px;*/
    /*height: 95vw;*/
}
.footer-style-mobile {
  display:none
}

.footer-mobile {
    margin-top: 50px;
    width: 95vw;
    /*height: 495px;*/
    display: none;
}

.footer-media {
    display: flex;
    justify-content: space-between;
    /*height: 95vw;*/
}

.footer-section-title {
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    height: 100px;
}

.footer-section-title-media {
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    margin-right: 15px!important;
}

.header-section-title {
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    margin-right: 15px;
}

.header-section-title:hover {
    cursor: pointer;
    color: var(--background-color)  !important;
    font-display: optional;
}

input[type=checkbox] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

input:checked ~ .tab-content {
    border-top: solid var(--main-color) 2px;
    max-height: 100vh;
    padding: 1em;
}

.mint_font{
    color:rgb(209, 205, 205);
}

.balance_font{
    color:rgb(209, 205, 205);
}

.airdrop_title_font{
    color:rgb(245, 119, 117);
    font-family: AREMBO;
    font-size: 4vw;
}

.comming_title_font{
    color:rgb(245, 119, 117);
    margin-left: 200px;
    font-family: AREMBO;
    font-size: 2vw;
}

.max{
    cursor: pointer !important;
    color:rgb(209, 205, 205);

}

input:checked + .tab-label {
}

input:checked + .tab-label::after {
    transform: rotate(90deg);
}

.mobile-img-container {
    display: none;
}

.img-flyingbuddies-mobile {
    display: none;
}

.grid-buddies-mobile{
    display: none;
}

.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 7px;
    font-size: 18px;
    border: none;
    cursor: pointer;
}



  .media_image {
    margin-left: 0px;
    flex:1;
    filter: opacity(0.8);
    cursor: pointer; /* 鼠标指针移上去显示为“小手” */
    width: 40px;
    height:40px;
  }
  
  .media_image:hover{
    transform: scale(1.08);
    filter: opacity(1);
  
  }

  .liz_header {
      display: flex;
      align-items: center; /* 垂直居中 */
    width: 100%;
    height: 80px;
    z-index:5;
    background-color: rgba(27, 34, 38, 0.9);
}

  .affix {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    z-index:5;
}

.affix_menu_mobile{
    display:none;
}

.affix_menu{
    height: 50px;
}

.head-section-img {
    height:50px;
}
.head-button {
    position:absolute;
    right: 20px;
    height: 40px;
    width: auto;
    min-height: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--Highlight,#00ecd5);
    border-radius: 20px;
    font-family:  All-round-gothic, sans-serif;
    color: var(--main-color) ;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;
  }
  .head-button-mobile:hover {
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color:var(--background-color) ;
    box-shadow: 0 0 16px 0 var(--background-color) ;
}
.chain-button {
    position:absolute;
    right: 240px;
    width: auto;
    height: 40px;
    font-family:  All-round-gothic, sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
}
.chain-button-mobile {
    display:none;
}



.chain-pic {
    position:absolute;
    top:0;
    bottom:0;
    left:-25px;
    margin: auto;
    width: auto;
    height: 40px;
    display: flex;
    z-index: 111;
}

  .head-button:hover {
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color:var(--background-color) ;
    box-shadow: 0 0 16px 0 var(--background-color) ;
}

.head-button-mobile {
    display:none;
}

.liz_menu_sect {
    position: absolute;
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 580px;
    left: 25%;
}

.liz_menu_button {
    position: absolute;
    padding: 0px 12px;
    color: rgb(255, 255, 255);
    height: 50px;
  }

.liz_menu_button:hover {
    transform: scale(1.08);
}


.liz_menu_mobile {
    display: none;
}

.liz_language{
    right: 10px;
    position:absolute;
    width: 60px;
    display:flex;
    align-items: center;
}
.liz_language:hover {
    cursor: pointer;
    transform: scale(1.08);
}

.liz_language_mobile{
    display: none;
}

.liz_menu_item_mobile {
    color: black !important;
    font-family:  All-round-gothic;
    margin-right: 15px!important;
}

.liz_menu_list{
    font-family: Prototype;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: rgba(255, 255, 255, 0.8);
    text-transform: capitalize;
}

.liz_menu_right_sect{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.liz_menu_list ul{
    margin-left: 65px;
    margin-right: 96px;
    min-width: 409px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
}
.liz_menu_list ul li{
    position: relative;
    cursor: pointer;
}


.liz_menu_list ul li a {
    font-family: Prototype;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media only screen and (max-width:1730px) {
    /* .btn-connect {
        margin-right: 120px;
    } */
    /* .btn-icon {
        display: none;
    } */
    /* .nav-container {
        width: auto;
        padding-left: 30px;
        margin-left: 50px;
    }
    .navbar-nav>li>a {
        margin-left: 0px !important;
    } */
}

@media only screen and (max-width:1600px) {
    /* .btn-icon {
        display: none;
    } */
}

@media only screen and (max-width:1420px) {


}

@media only screen and (max-width:1460px) {

}
@media only screen and (max-width:1200px) {
    .main-section-title{
        display: none;
    }
    .btn-grab {
        width: 70%;
        padding: 10px 30px;
    }
    .slider-container-div {
        margin-top: -14%;
        margin-bottom: -10%;
    }
    .footer {
        display: none;
    }
    .footer-mobile {
        display: block;
    }
    .main-section-title{
        display: none;
    }
    .main-section-subtitle-title
    {
        display: none;
    }
    .chain-pic {
        position:absolute;
        top:0;
        bottom:0;
        left:-15px;
        margin: auto;
        width: auto;
        height: 40px;
        display: flex;
        z-index: 111;
    }

    .main-section-title-mobile {
        margin-top: 180px;
        display: block;
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        font-size: 6.5vw;
        z-index: 1;
    }
    .accessory-img {
        margin-top: -280px;
        width: 100%;
    }
    .main-section-subtitle-mobile {
    }
        display: block;
    .main-section-img-container {
        display: none;
    }
    .mobile-img-container{
        width: 90vw;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
    .mobile-img-container img {
        width: 33vw;
    }
    .mobile-img-container img:nth-child(2) {
        width: 50vw;
        margin-left: -15vw;
        margin-right: -15vw;
        margin-top: 10%;
        position: relative;
        z-index: 11;
    }
    .mint-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .wallet-bg {
        align-items: center;
    }
    .connect-wallet-btn {
        margin-right: -7%;
        margin-top: 55%;
    }
    .star-btn-container {
        margin-left: 20%;
    }
    .mint-bg {
        font-size: 15px;
    }
    .img-flyingbuddies {
        display: none;
    }

    .div-para1 {
        width: 70vw;
        margin-top: 60px;
        margin-right: 10vw;
    }
    .div-para2 {
        flex-direction: column-reverse;
    }
    .twitter-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .twitter-img {
        display: none;
    }

    .twitter-btn {
        background-color: #4853ee;

    }

    .twitter-img-mobile {
        display: block;
        width: 12vw;
        margin-right: 40vw;
        margin-top: -4%;
        margin-bottom: -10%;
        z-index: 22;
        pointer-events: none;
    }
    .chain-button {
        display:none;
    }
    .chain-button-mobile {
        position:absolute;

        right: 200px;
        width: auto;
        height: 40px;
        font-family:  All-round-gothic, sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
    }
    .accessory-img_1 {
        margin-top: 20px;
        width: 90%;
        height: 90%;
    }
    .liz_language{
        display:none;
    }
    .liz_language_mobile{
        display: block;
        right: 145px;
        position:absolute;
        width: 60px;
    }
    .accessory-img_2 {
        margin-top: 80px;
        width: 200px;
        height: 200px;
        display: none;
    }

    .div-para2-text {
        width: 90%;
        height: 90%;
    }

    .tab {
        width: 90vw;
    }

    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }
      .head-button-mobile {
          position:absolute;
          display: block;
          right:50px;
          height: 40px;
          width: auto;
          border-style: solid;
          border-width: 1px;
          border-color: var(--Highlight,#00ecd5);
          border-radius: 16px;
          font-family:  All-round-gothic, sans-serif;
          color: #f7f6f6;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          background-color: transparent;
      }

      .head-button-mobile:hover {
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        border-color:var(--background-color) ;
        box-shadow: 0 0 16px 0 var(--background-color) ;
    }
    .head-button {
        display:none;
    }

      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:3%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
} 

@media only screen and (max-width:1180px) {
    .mint-instruction {
        width: 90%;
    }
    .main-section-title{
        display: none;
    }
    
    .main-section-title-mobile {
        display: block;
    }
    .main-section-subtitle-mobile {
        display: block;
    }
    .main-section-img-container {
        display: none;
    }
    .airdrop_title_font
    {
        font-size: 10vw;
    }
    .comming_title_font{
        margin-left: 100px;
        font-size: 6vw;
    }
    .affix_menu{
        display:none;
    }
    .liz_menu_mobile {
        right: 0px;
        position:absolute;
        width: 60px;
        display:flex;
        align-items: center;
    }
    .head-section-img {
        height:50px;
    }
    .head-button-mobile {
        position:absolute;
        display: block;
        right:50px;
        height: 40px;
        width: auto;
        border-style: solid;
        border-width: 1px;
        border-color: var(--Highlight,#00ecd5);
        border-radius: 16px;
        font-family:  All-round-gothic, sans-serif;
        color: #f7f6f6;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        background-color: transparent;
    }

    .head-button-mobile:hover {
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        border-color:var(--background-color) ;
        box-shadow: 0 0 16px 0 var(--background-color) ;
    }
    .head-button {
        display:none;
    }
    .nav-container {
        width: 30px;
    }
    .twitter-img-mobile {
        display: block;
        width: 27vw;
        margin-right: 57vw;
        margin-top: -6%;
        margin-bottom: -21%;
        z-index: 22;
        pointer-events: none;
    }
    .div-para3 {
        align-items: center;
    }
    .div-para2-text {
        width: 90%;
        height: 90%;
    }
    .div-para3-text {
        margin-top: 20px;
        width: 90%;
        height: 90%;
    }
    .div-para4-text {
        margin-top:20px;
        width: 80%;
    }
    .div-para2 {
        flex-direction: column-reverse;
    }
    .slider-container-div {
        margin-top: -160px;
        margin-bottom: 0px;
    }
    .btn-grab {
        padding: 10px 30px;
    }

    .main-section-subtitle-mobile{
        font-size: 4vw;
        margin-top: 0%;
    }
    .navbar-brand {
        margin-left: 2vw !important;
    }
    .nav-container {
        background-color: var(--background-color) !important;
        display: flex;
        border-radius: 3px;
        border: solid 0.2px #666666;
        margin-left: 70px;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -45px;
        margin-right: 10px;
        padding-right: 10px;
        padding-left: 0px;
    }
    .star-btn-container {
        padding: 10vw;
    }
    .btn-grab {
        font-size: 5vw;
    }
    .div-para1{
        width: 60vw;
    }

    .grid-buddies {
        margin-top: -230px;
    }
    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:10%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
      .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 50px;
        display: block;
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 60px!important;
        margin-right: 15px!important;
        width: 192px!important;
        vertical-align: middle;
    
    }
    
    .slider-container-div {
    display: none;
     }
     .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    
    .footer-section-title-media-mobile {
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        margin-right: 15px!important;
    }
    
    .footer-media-mobile {
        width: 105vw;
        display: flex;
        justify-content: center;
        height: 40px;
    
        /*height: 95vw;*/
    }
    
    .slider-container-div {
    display: none;
     }

    .img {
        display: inline-block;
        width:20px;
        height:20px;
        vertical-align: middle;
    }


    

    
}

@media only screen and (max-width:767px) {
    .mint-instruction {
        width: 90%;
    }
    .nav-container {
        width: 310px;
    }
    .affix_menu{
        display:none;
    }
    .liz_menu_mobile {
        right: 0px;
        position:absolute;
        width: 60px;
        display:flex;
        align-items: center;
    }
    .tab-content {
        max-height: 0;
        padding: 0 1em;
        color: midnight;
        transition: all .35s;
        box-sizing: border-box;
        color: #f1f1f1;
        font-size: 10px;
    }
    .twitter-img-mobile {
        display: block;
        width: 27vw;
        margin-right: 57vw;
        margin-top: -6%;
        margin-bottom: -21%;
        z-index: 22;
        pointer-events: none;
    }
    .div-para3 {
        align-items: center;
    }

    .div-para3-text {
        margin-top: 20px;
        width: 90%;
        height: 90%;
    }
    .div-para4-text {
        margin-top:20px;
        width: 80%;
    }

    .slider-container-div {
        margin-top: -160px;
        margin-bottom: 0px;
    }
    .btn-grab {
        padding: 10px 30px;
    }
    .main-section-title-mobile {
        margin-top: 100px;
        display: block;
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        font-size: 6.5vw;
        z-index: 1;
    }
    .accessory-img {
        margin-top: -140px;
        width: 100%;
    }
    .main-section-subtitle-mobile{
        font-size: 4vw;
        margin-top: 0%;
    }
    .navbar-brand {
        margin-left: 2vw !important;
    }
    .nav-container {
        background-color: var(--background-color) !important;
        display: flex;
        border-radius: 3px;
        border: solid 0.2px #666666;
        margin-left: 70px;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -45px;
        margin-right: 10px;
        padding-right: 10px;
        padding-left: 0px;
    }
    .star-btn-container {
        padding: 10vw;
    }
    .btn-grab {
        font-size: 5vw;
    }
    .div-para1{
        width: 60vw;
    }

    .grid-buddies {
        margin-top: -230px;
    }
    .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    
    .slider-container-div {
    display: none;
     }
    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }

    
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:10%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
      .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    .footer-section-title-media-mobile {
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        margin-right: 15px!important;
    }
    
    .footer-media-mobile {
        width: 105vw;
        display: flex;
        justify-content: center;
        height: 40px;
    
        /*height: 95vw;*/
    }
    
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    
    .slider-container-div {
    display: none;
     }

    .img {
        display: inline-block;
        width:20px;
        height:20px;
        vertical-align: middle;
    }

    .footer-style {
     display:none;
    }
    .footer-style-mobile {
        margin-top: 50px;
        display: inline-block;
        text-align:center;       
     }
      
}

@media only screen and (max-width:400px) {
    .mint-instruction {
        width: 90%;
    }
    .affix_menu{
        display:none;
    }
    .liz_menu_mobile {
        right: 0px;
        position:absolute;
        width: 60px;
        display:flex;
        align-items: center;
    }
    .head-button-mobile {
        position:absolute;
        display: block;
        right:50px;
        height: 40px;
        width: auto;
        border-style: solid;
        border-width: 1px;
        border-color: var(--Highlight,#00ecd5);
        border-radius: 16px;
        font-family:  All-round-gothic, sans-serif;
        color: #f7f6f6;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        background-color: transparent;
    }
    .head-button-mobile:hover {
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        border-color:var(--background-color) ;
        box-shadow: 0 0 16px 0 var(--background-color) ;
    }
    .head-button {
        display:none;
    }

    .nav-container {
        width: 310px;
    }
    .twitter-img-mobile {
        display: block;
        width: 27vw;
        margin-right: 57vw;
        margin-top: -6%;
        margin-bottom: -21%;
        z-index: 22;
        pointer-events: none;
    }
    .div-para3 {
        align-items: center;
    }
    .div-para2-text {
        width: 90%;
        height: 90%;
    }
    .div-para3-text {
        margin-top: 20px;
        width: 90%;
        height: 90%;
    }
    .div-para4-text {
        margin-top:20px;
        width: 80%;
    }
    .div-para2 {
        align-items: center;

    }
    .slider-container-div {
        margin-top: -160px;
        margin-bottom: 0px;
    }
    .btn-grab {
        padding: 10px 30px;
    }
    .main-section-title-mobile {
        display: block;
    }
    .main-section-subtitle-mobile{
        font-size: 4vw;
        margin-top: 0%;
    }
    .navbar-brand {
        margin-left: 2vw !important;
    }
    .nav-container {
        background-color: var(--background-color) !important;
        display: flex;
        border-radius: 3px;
        border: solid 0.2px #666666;
        margin-left: 70px;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -45px;
        margin-right: 10px;
        padding-right: 10px;
        padding-left: 0px;
    }
    .star-btn-container {
        padding: 10vw;
    }
    .btn-grab {
        font-size: 5vw;
    }
    .div-para1{
        width: 60vw;
    }

    .grid-buddies {
        margin-top: -230px;
    }

    .head-twitter {
        display: none;
      }
      .head-opensea {
        display: none;
      }
      .head-discord {
        display: none;
      }

    
      .head-address {
        position: absolute;
        padding: 0px 12px;
        top: 0%;
        right:5%;
        height: 55px;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.944);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        line-height: 55px;
        font-weight: 500
      }
      .flex_container_partners {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners {
        display: none;
       
       }

       .main-parnters-title {
        margin-top: 20px;
        display: block;
        color: var(--main-color) !important;
        font-family:  All-round-gothic;
        font-size: 6vw;
        text-shadow: 1px 0 0 rgb(66, 51, 51), -1px 0 0 rgb(204, 204, 204), 0 1px 0 rgb(105, 105, 105), 0 -1px 0 rgb(156, 156, 156), 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 rgb(10, 10, 10);
        filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
    }

       .flex_container_partners-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 35px;
        width: 100%;
        justify-content: center;
    
    }
    
    .flex_container_partners-mobile img {
        display: inline-block;
        height: 30px!important;
        margin-right: 15px!important;
        width: 96px!important;
        vertical-align: middle;
    
    }
    
    .slider-container-div {
    display: none;
     }

    .img {
        display: inline-block;
        width:20px;
        height:20px;
        vertical-align: middle;
    }

    .footer-style {
     display:none;
    }
    .footer-style-mobile {
        margin-top: 50px;
        display: inline-block;
        text-align:center;       
     }
}
.footer-section-title-mobile {
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    margin-top: 50px;
    width: 100vw;
    height: 40px;
}
.footer-section-title-media-mobile {
    color: var(--main-color) !important;
    font-family:  All-round-gothic;
    margin-right: 15px!important;
}

.footer-media-mobile {
    width: 105vw;
    display: flex;
    justify-content: center;
    height: 40px;

    /*height: 95vw;*/
}

.progress-bar-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    font-size: 0.5em;
}
.progress-bar {
    border: lightgray solid 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: content-box;
    position: relative;
    margin: 1em;
    border-radius: 1em;
    justify-content: flex-start;
    height: 30px;
    width: 100%;
    padding: 0.5em;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
}
.progress-bar-fill {
    display: block;
    border-radius: 0.8em;
    background-image: linear-gradient(111deg, #00ffc1, #9400fd);
    position: relative;
    overflow: hidden;
}
.supply-minted {
    width: 100%;
    position: absolute;
    font-family:  All-round-gothic;
    font-size: 22px;
    filter: drop-shadow(1px 1px 1px rgb(44, 44, 44));
}
