/*@font-face {*/
/*    font-family: MondayMadnessFont-outline;*/
/*    src: url('./assets/MondayMadnessFont-Outline-Regular.ttf');*/
/*}*/

@font-face {
    font-family: Sora;
    src: url('./assets/fonts/Sora-Regular.ttf'); 

}

@font-face {
    font-family: MondayMadnessFont-fill;
    src: url('./assets/fonts/MondayMadnessFont-Filled-Regular.ttf'); 

}

@font-face {
    font-family: All-round-gothic;
    src: url('./assets/fonts/FontsFree-Net-All-Round-Gothic-W01-Bold.ttf'); 

}

/*@font-face {*/
/*    font-family: TheSideStream;*/
/*    src: url('./assets/The\ Sidestream.ttf');*/
/*}*/

@font-face {
    font-family: RamblaBold;
    src: url('./assets/fonts/Rambla-Bold.ttf');
}

@font-face {
    font-family: DancingScript;
    src: url('./assets/fonts/DancingScript-Bold.ttf');
}

@font-face {
    font-family: MondayMadnessFont;
    src: url('./assets/fonts/MondayMadnessFont-Filled-Regular.ttf');
}

@font-face {
    font-family: AREMBO;
    src: url('./assets/fonts/AREMBO.ttf');
}
@font-face {
    font-family: SWEETGIRL;
    src: url('./assets/fonts/SWEETGIRL.ttf');
}
@font-face {
    font-family: Prototype;
    src: url('./assets/fonts/Prototype.ttf');
}

iframe {
    pointer-events: none;
}
